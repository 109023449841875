import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuBannerComponent = _resolveComponent("MenuBannerComponent")!
  const _component_MainMenuComponent = _resolveComponent("MainMenuComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_CookieBannerComponent = _resolveComponent("CookieBannerComponent")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, { class: "section" }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuBannerComponent),
          _createVNode(_component_MainMenuComponent),
          _createVNode(_component_router_view),
          _createVNode(_component_FooterComponent),
          _createVNode(_component_CookieBannerComponent),
          _createElementVNode("h1", null, _toDisplayString(_ctx.updateAvailable), 1),
          _createVNode(_component_v_snackbar, {
            modelValue: _ctx.updateAvailable,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updateAvailable) = $event)),
            timeout: 6000,
            top: "",
            right: "",
            color: "info"
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "A new version is available.", -1)),
              _createVNode(_component_v_btn, {
                color: "white",
                class: "mr-4",
                onClick: _ctx.refreshApp
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Refresh")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                color: "white",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateAvailable = false))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Dismiss")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}