import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { co } from "@fullcalendar/core/internal-common";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Home - LOLA Workshops",
    },
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Admin Login - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      requiresAuth: true, // Protect this route
      title: "Admin - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
  },
  // {
  //   path: "/events",
  //   name: "events",
  //   meta: {
  //     requiresAuth: true, // Protect this route
  //     title: "Events - LOLA Workshops",
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "events" */ "../views/EventsView.vue"),
  // },
  {
    path: "/event-details/:id",
    name: "event-details",
    meta: {
      title: "Event Details - LOLA Workshops",
    },
    component: () =>
      import(
        /* webpackChunkName: "event-details" */ "../views/EventDetailsView.vue"
      ),
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: {
      title: "Checkout - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
  },
  {
    path: "/event-edit/:id",
    name: "event-edit",
    component: () =>
      import(/* webpackChunkName: "event-edit" */ "../views/EventEditView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "About - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/behaviour-policy",
    name: "behaviour policy",
    meta: {
      title: "behaviour Policy - LOLA Workshops",
    },
    component: () =>
      import(
        /* webpackChunkName: "behaviour-policy" */ "../views/BehaviourPolicyView.vue"
      ),
  },
  {
    path: "/half-term",
    name: "half-term",
    meta: {
      title: "Half Term - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "half-term" */ "../views/HalfTermView.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms and conditions",
    meta: {
      title: "Terms and Conditions - LOLA Workshops",
    },
    component: () =>
      import(
        /* webpackChunkName: "terms-and-conditions" */ "../views/TermsAndConditionsView.vue"
      ),
  },
  {
    path: "/faqs",
    name: "faqs",
    meta: {
      title: "FAQs - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/FaqsView.vue"),
  },
  {
    path: "/basket",
    name: "basket",
    meta: {
      title: "Basket - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "basket" */ "../views/BasketView.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: {
      title: "Registration - LOLA Workshops",
    },
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/RegistrationView.vue"
      ),
  },
  {
    path: "/booking/:id",
    name: "booking",
    meta: {
      title: "Booking - LOLA Workshops",
    },
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookingView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (typeof to.meta.title === "string") {
    document.title = to.meta.title;
  }
  // next();

  const auth = getAuth();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        next(); // User is authenticated, allow access
      } else {
        next({ name: "login" }); // User is not authenticated, redirect to login
      }
    });
  } else {
    next(); // Route does not require authentication, proceed
  }
});

export default router;
